import React, { FunctionComponent, useState } from 'react';
import { Card, CardContent, CardMedia, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { IWork } from '../../utils/interfaces';

interface IProjectProps {
    project: IWork
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            width: '100%'
        },
        media: {
            height: 0,
            paddingTop: '100%',
            backgroundSize: 'contain'
        },
        title: {
            fontWeight: 700,
            fontSize: '1.5rem',
            marginBottom: theme.spacing(2)
        }
    })
);

const Project: FunctionComponent<IProjectProps> = ({ project }) => {
    const classes = useStyles();
    const [isEnter, setIsEnter] = useState<boolean>(false);

    return (
        <Card elevation={!isEnter ? 2 : 10} onMouseEnter={() => setIsEnter(true)} onMouseLeave={() => setIsEnter(false)}>
            <CardMedia className={classes.media} image={project.image.path} title={project.image.name} />
            <CardContent>
                <Typography variant='h2' className={classes.title}>{project.name}</Typography>
                {project.preview}
            </CardContent>
        </Card>
    );
};

export default Project;