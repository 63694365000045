import React, { useEffect, useState } from 'react';
import { createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import Layout from '../components/layout';
import Header from '../components/layouts/header';
import SEO from '../components/seo';

import Project from '../components/portfolio/Project';
import { Link } from 'gatsby';
import { IProject } from '../utils/interfaces';
import { getImageRouteFromDrupalApi, getPortfolio } from '../utils/functions';
import { CMS_ROUTE } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        projects: {
            marginTop: theme.spacing(5),
            display: 'grid',
            gridGap: '2.5rem',
            gridTemplateColumns: 'repeat(auto-fill, minmax(295px, 1fr))'
        },
        link: {
            textDecoration: 'none'
        }
    })
);

const Portfolio = () => {
    const classes = useStyles();
    const [portfolio, setPortfolio] = useState<Array<IProject>>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                setPortfolio(await getPortfolio());
            } catch (error) {
                alert(error);
            } finally {
                setLoading(false);
            }
        }
        getData();
    }, []);

    return (
        <Layout page='Portafolio'>
            <SEO title='Portafolio' />

            <Header siteTitle='Portafolio' />

            <main>
                <div className={classes.projects}>
                    {loading ? <LinearProgress /> : null}
                    {portfolio.map(portfolioItem => (
                        <Link key={portfolioItem._id} to={`/portafolio/item?id=${portfolioItem._id}`} className={classes.link}>
                            <Project project={{
                                description: portfolioItem.summary,
                                idWork: portfolioItem._id,
                                name: portfolioItem.project_name,
                                image: {
                                    name: portfolioItem.project_name,
                                    path: CMS_ROUTE + portfolioItem.main_image.path
                                },
                                preview: <div>{portfolioItem.summary}</div>
                            }} />
                        </Link>
                    ))}
                </div>
            </main>
        </Layout>
    )
};

export default Portfolio;